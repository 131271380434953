/* ClientsListItem CSS */

.ClientsListItem {
    align-items: center;
    display: grid;
    grid-template-columns: 95% 5%;
    max-width: 750px;
    min-height: 60px;
    padding-left: 20px;
}
