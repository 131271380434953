/* Mobile Menu CSS */

.MobileMenu {
    --animate-duration: 0.5s; 
	background-color: #fff;
	bottom: 0;
	box-shadow: -2px 0 8px #999;
	overflow: scroll;
	padding: 100px 60px 20px 40px;
	position: fixed;
	right: 0;
	top: 0;
	width: 300px;
	z-index: 999;
}

.MobileMenu__links {
    margin-bottom: 50px;
}

.MobileMenu__links__link {
    display: block;
	margin-bottom: 25px;
	transition: all 0.3s;
}

.MobileMenu__links__link:hover, 
.MobileMenu__link:hover {
	opacity: 0.7;
}


@media only screen and (max-width: 340px) {

    .MobileMenu {
        display: block;
    }
}