/* CopyToClipboard CSS */

.CopyToClipboard {
    background-image: url('./copy.svg');
    background-size: contain;
    cursor: pointer;
    display: inline-block;
    height: 22px;
    margin: 0 10px;
    opacity: 0.4;
    transform: translateY(3px);
    width: 22px;
}