/* FieldListItem CSS */

.FieldListItem.card {
    align-items: center;
    display: grid;
    grid-template-columns: 29% 28% 20% 20% 3%;
    margin-bottom: 30px;
    min-height: 80px;
    padding-left: 20px;
}
