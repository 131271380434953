/* BTDTooltip CSS */

.BTDTooltip__container {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.BTDTooltip {
    background-color: rgba(50,50,50,0.8);
    border-radius: 4px;
    top: calc(100% + 12px);
    color: white;
    font-size: 12px;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    z-index: 1000;
}

.BTDTooltip__content {
    padding: 8px;
}

.BTDTooltip__arrow {
    border-color: transparent transparent rgba(50,50,50,0.8) transparent;
    border-style: solid;
    border-width: 8px; 
    height: 0;
    left: 50%;
    position: absolute;
    bottom: 100%;
    transform: translateX(-50%);
    width: 0;
}


.BTDTooltip__container:hover .BTDTooltip {
    opacity: 1;
}