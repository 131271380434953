/* Overelay CSS */

.Overlay {
	backdrop-filter: blur(3px);
	background-color:  rgba(255, 255, 255, 0.4);
	bottom:  0;
	left:  0;
	position:  fixed;
	right:  0;
	top:  0;
	z-index: 10;
}

.Overlay-transparent {
	background-color: transparent;
}