* {
	padding: 0;
	margin: 0;
	list-style-type: none;
	box-sizing: border-box;
	line-height: 1;
}

body {
	color: #253551;
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
}

img {
	height: auto;
	width: 100%;
}

/* text */

a {
	text-decoration: none;
	transition: all 0.35s;
}

h1 {
	font-size: 28px;
	font-weight: 500;
}

h3 {
	font-size: 21px;
	font-weight: 500;
	line-height: 1.5;
}

p {
	font-size: 16px;
	line-height: 1.5;
}

label {
	color: #253551;
	font-size: 15px;
	font-weight: 500;
}

.light {
	font-weight: 400;
}

.medium {
	font-weight: 500;
}

.semi-bold {
	font-weight: 600;
}

.bold {
	font-weight: 700;
}

.opacity {
	opacity: 65%;
}

.centre {
	text-align: center;
}

.italic {
	font-style: italic;
}

.underline {
	text-decoration: underline;
}

/* Colours */

.black {
	color: #000;
}

.blue {
	color: #253551;
}

.green {
	color: #6DA726;
}

.light-blue {
	color: #0454A8;
}

.light-grey {
	background-color: #F6F8FB;
}

.orange {
	color: #FE5100;
}

.pink {
	color: #FF38F4;
}

.red {
	color: #BC3B3B;
}

.white {
	color: #fff;
}

.yellow {
	color: #D87F2D;
}

/* Forms */

.form__title {
	margin-bottom: 40px;
}

.form__flexContainer {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.form__grid-2 {
	display: grid;
	grid-template-columns: repeat(2, calc(50% - 10px));
	column-gap: 20px;
}

.form__grid-3 {
	column-gap: 30px;
	display: grid;
	grid-template-columns: repeat(3, calc(33.3333% - 20px));
	row-gap: 30px;
	margin-bottom: 30px;
	max-width: 600px;
}

.form__label {
	display: flex;
	margin-bottom: 8px;
}

.form__link {
	color: #1976d2;
	cursor: pointer;
	margin-bottom: 20px;
}

.form__text {
	margin: 10px 0;
}

.form__tabs {
	border-bottom: 1px solid #D5D5D5;
	margin-bottom: 20px;
}

.form__tabs__tab {
	cursor: pointer;
	display: inline-block;
	margin-right: 30px;
	padding-bottom: 10px;
	width: max-content;
}

.form__tabs__tab.active {
	border-bottom: 1px solid #FE5100;
}

.form__slider {
	margin: 40px 0;
}

::-webkit-input-placeholder { /* Edge */
	font-family: 'Poppins', sans-serif !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	font-family: 'Poppins', sans-serif !important;
}

::placeholder {
	font-family: 'Poppins', sans-serif !important;
}

.form__gallery {
	display: flex;
	margin-top: 60px;
}

.form__gallery__item {
	align-items: center;
	background-color: #F3F5F7;
	border: 1px solid #E0E5EA;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 165px;
	justify-content: end;
	margin: 0 40px 40px 0;
	max-width: 165px;
	padding-bottom: 37px;
	transition: all 0.25s;
	width: 100%;
}

.form__gallery__item:hover {
	opacity: 0.7;
}

.form__gallery__item__icon {
	background-size: cover;
}

.form__gallery__item__text {
	color: #2A3042;
	font-size: 15px;
	font-weight: 400;
	margin-top: 20px;
}

/* Layout */

.card {
	border: 1px solid #DCDFE2;
    border-radius: 4px;
    box-shadow: 0px 0px 9px rgba(0,0,0, 0.080556);
    margin-bottom: 50px;
	position: relative;
    width: 100%;
}

.table {
	margin-bottom: 60px;
    width: 100%;
}

.tr {
	align-items: center;
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: grid;
}

.tr:last-child {
    border-bottom: 1px solid #E1E1E1;
}

.th {
    color: #1D1F23;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
    padding: 0 5px;
}

.td {
    color: #1D1F23;
    font-size: 14px;
    font-weight: 500;
}

.clickable {
	cursor: pointer;
	transition: all 0.3s;
}

.clickable:hover {
	opacity: 0.7
}

/* Media queries */

@media only screen and (max-width: 900px) {

	.form__grid-2, .form__flexContainer {
		display: block;
	}
	
}
