/* BTDCheckboxField */

/* Customize the label (the container) */
.BTDCheckboxField__label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    display: block;
    font-size: 16px;
    margin-bottom: 30px;
    padding-left: 35px;
    padding-top: 5px;
    position: relative;
    user-select: none;
}

/* Hide the browser's default checkbox */
.BTDCheckboxField__input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
}

/* Create a custom checkbox */
.BTDCheckboxField__checkmark {
    background-color: #eee;
    border-radius: 3px;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
}

/* On mouse-over, add a grey background color */
.BTDCheckboxField__label:hover input ~ .BTDCheckboxField__checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.BTDCheckboxField__label input:checked ~ .BTDCheckboxField__checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.BTDCheckboxField__checkmark:after {
    content: "";
    display: none;
    position: absolute;
}

/* Show the checkmark when checked */
.BTDCheckboxField__label input:checked ~ .BTDCheckboxField__checkmark:after {
    display: block;
    font-family: inherit;
}

/* Style the checkmark/indicator */
.BTDCheckboxField__label .BTDCheckboxField__checkmark:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    border: solid white;
    border-width: 0 3px 3px 0;
    left: 9px;
    height: 10px;
    transform: rotate(45deg);
    top: 5px;
    width: 5px;
} 