/* FormViewScreen CSS */

.FormView__info {
    align-items: center;
    background-color: #f5f7fa;
    border-radius: 4px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    margin-bottom: 40px;
    padding: 30px 20px;
    row-gap: 25px;
}

.FormView__subtitle {
    margin-bottom: 20px;
}

.FormView__add {
    width: 100%;
}