/* SubmissionsListItem CSS */

.SubmissionsListItem.card {
    align-items: center;
    cursor: pointer;
    display: grid;
    grid-template-columns: 10% 25% 30% 35%;
    margin-bottom: 20px;
    max-width: 1000px;
    min-height: 60px;
    padding-left: 20px;
    transition: all 0.3s;
}

.SubmissionsListItem.card:hover {
    opacity: 0.7;
}

.SubmissionsListItem__modalDiv {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 20px;
}
