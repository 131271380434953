/* BTDSelectField */

.BTDSelectField {
    font-size: inherit;
    margin-bottom: 30px;
    position: relative;
}

.BTDSelectField.medium {
    width: calc(50% - 10px);
}

.BTDSelectField.small {
    width: calc(25% - 20px);
}

.BTDSelectField__description {
}

.BTDSelectField__label {
    background-color: #fff;
    font-size: inherit;
    opacity: 0.7;
    position: absolute;
    left: 20px;
    top: 25px;
    transition: all 0.3s;
    z-index: -1;
}

.BTDSelectField__label.active {
    font-size: 11px;
    left: 15px;
    padding: 0 5px;
    top: -5px;
    z-index: 9;
}

.BTDSelectField__downArrow {
    background-image: url('./down-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 27px;
    transition: all 0.3s;
    width: 15px;
    z-index: -1;
}

.BTDSelectField__downArrow:-webkit-any(:checked), 
.BTDSelectField__downArrow:checked {
    transform: rotate(180deg);
}

.BTDSelectField__input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    line-height: 1.3;
    outline: none;
    padding: 20px;
    width: 100%;
}

.BTDSelectField__input::-ms-expand {
    display: none; /* Hide default arrow in Internet Explorer */
}

.BTDSelectField__error {
    color: red;
}

