/* FormListItem CSS */

.FormListItem {
    align-items: center;
    display: grid;
    grid-template-columns: 40% 35% 22% 3%;
    max-width: 1200px;
    min-height: 60px;
    padding-left: 20px;
}
