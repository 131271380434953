.Navigation {
	display: flex;
	align-items: center;
}

.Navigation__list {
	display: flex;
	align-items: center;
}

.Navigation__list__item {
	margin: 0 10px;
}

.Navigation__list__item__link {
	border-radius: 4px;
	color: #253551;
	display: block;
	font-size: 18px;
	padding: 10px;
	transition: all 0.3s;
}

.Navigation__list__item__link:hover {
	opacity: 0.7;
}
