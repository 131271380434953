/* BTDTextField */

.BTDTextField {
    font-size: inherit;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    z-index: 1;
}

.BTDTextField.medium {
    width: calc(50% - 10px);
}

.BTDTextField.small {
    width: calc(25% - 20px);
}

.BTDTextField__label {
    background-color: #fff;
    font-size: inherit;
    opacity: 0.7;
    position: absolute;
    left: 20px;
    top: 23px;
    transition: all 0.3s;
    z-index: -1;
}

.BTDTextField__label.active {
    font-size: 11px;
    left: 15px;
    padding: 0 5px;
    top: -5px;
    z-index: 9;
}

.BTDTextField__label.startIcon {
    left: 45px;
}

.BTDTextField__startIcon {
    background-repeat: no-repeat;
    background-size: contain;
    height: 28px;
    left: 10px;
    opacity: 0.4;
    position: absolute;
    top: 19px;
    width: 35px;
}

.BTDTextField__startIcon-search {
    background-image: url('./magnifying-glass.svg');
}

.BTDTextField__startIcon-clear {
    background-image: url('./close.svg');
    cursor: pointer;
}

.BTDTextField__input {
    background-color: transparent;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    font-family: inherit;
    font-size: inherit;
    display: flex;
    outline: none;
    padding: 18px 20px;
    width: 100%;
}

.BTDTextField__input.startIcon {
    padding: 18px 20px 18px 45px;
}

.BTDTextField__error {
    color: red;
}
